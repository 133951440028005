import React, { useEffect } from "react";
import AllLaunches from "./container/board";
import CreateBlack from "./container/create.tsx";
import NotFound from "./container/NotFound";
import BuyPage from "./container/BuyPage";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params';
import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { bsc, mainnet, base, polygon, sepolia } from 'wagmi/chains'
import Profile from "./container/Profile.tsx";
import EditProfile from "./container/EditProfile";
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from "@web3modal/react";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import './index.css';
import Faq from "./container/Faq";

const projectId = '4807d388fe495226b7fc14743af2e1d9'

const sepolia_c = {
  ...sepolia,
  rpcUrls: {
    default: {
      http: ["https://1rpc.io/sepolia"],
    },
  }
}

const apechain = {
  id: 33139,
  network: "apechain",
  name: "ApeChain",
  nativeCurrency: {
    name: "ApeCoin",
    symbol: "APE",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://apechain.calderachain.xyz/http"],
    },
  },
  blockExplorers: {
    default: {
      name: "ApeChain Exploerer",
      url: "https://explorer.curtis.apechain.com",
    },
  },
  testnet: false,
};

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    bsc,
    mainnet,
    base,
    // polygon,
    // sepolia_c,
    // apechain
  ],
  [w3mProvider({ projectId })],
)

export const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'wagmi',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: projectId,
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
})

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const App = () => {
  return (
    <Router>
      <QueryParamProvider>
        <div>
          <WagmiConfig config={config}>
            <Toaster
              position="top-right"
              reverseOrder={true}
              toastOptions={{ duration: 5000 }}
            >
              {(t) => (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => toast.dismiss(t.id)}
                >
                  <ToastBar onClick={() => alert(1)} toast={t} />
                </div>
              )}
            </Toaster>
            <Switch>
              <Route exact path="/">
                <AllLaunches />
              </Route>
              {/* <Route exact path="/MyContributions">
                <MyContributions />
              </Route> */}
              <Route exact path="/board">
                <AllLaunches />
              </Route>
              <Route exact path="/create">
                <CreateBlack />
              </Route>
              <Route exact path="/Buy">
                <BuyPage />
              </Route>
              <Route exact path="/Profile">
                <Profile />
              </Route>
              <Route exact path="/EditProfile">
                <EditProfile />
              </Route>
              <Route exact path="/FAQ">
                <Faq />
              </Route>
              <Route exact path="/NotFound">
                <NotFound />
              </Route>
            </Switch>
          </WagmiConfig>
          <Web3Modal
            projectId={projectId}
            ethereumClient={ethereumClient}
          />
        </div>
      </QueryParamProvider>
    </Router>
  );
};

export default App;
