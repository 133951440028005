import React, { useState, useEffect, useRef } from 'react'
import '../App.css'
import '../styles/MainContainer.css'
import './aboutus.css'
import 'react-datepicker/dist/react-datepicker.css'
import TopBar from '../components/TopBar.jsx'
import faq from '../icons/faq.webp'
import arrow from '../../public/img/Yellow Arrow.png'
import arrowup from '../../public/img/Yellow Arrow-up.png'
function Faq() {
  const [visibleSection, setVisibleSection] = useState(null) // Manages which section is visible
  const [toggle, setToggle] = useState(true)

  const toggleVisibility = section => {
    if (visibleSection === section) {
      setVisibleSection(null) // Close the section if it's already open
    } else {
      setVisibleSection(section) // Open the clicked section and close others
    }
  }
  return (
    <div>
      <div className="GlobalContainer">
        <div style={{ zIndex: 1 }}>
          <TopBar />
          <div className='p-6 mt-[147px] sm:mt-0 md:p-[80px] flex flex-col-reverse xl:flex-row gap-8 items-center xl:items-start xl:gap-[0px] text-white justify-center'>
            <div className='flex flex-col max-w-[800px] gap-6 md:gap-[40px]'>
              <div className="m-auto px-4 sm:px-12 sm:py-10">
                <h1 className="text-[48px] leading-[110%] tracking-[-2%] font-bold">Master Baker is here to answer your questions</h1>
                <section className="lg:mx-auto pt-4 lg:py-[30px] w-full lg:w-[741px] min-w-0">
                  <div
                    className="Text1 text-[20px] font-light"
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    These are the most frquent asked questions about
                    Hotlaunch.fun. Can't find What you are looking for?
                  </div>
                  <a href="https://t.me/" target="_blank" className="p-[4px]">
                    <div
                      className="Text1 text-[20px]"
                      style={{
                        textAlign: 'center',
                        color: '#f500ff',
                        textDecoration: 'underline'
                      }}
                    >
                      Chat to our friendly team!
                    </div>
                  </a>
                </section>
              </div>
              <div className="m-auto sm:px-12 w-full">
                <div className="flex justify-start gap-0 relative">
                  <div className="absolute h-[48px] bg-[#f600ff] rounded-[8px] transition-all duration-300 ease-in-out" style={{ width: `${toggle ? '120px' : '140px'}`, left: `${toggle ? '0px' : '120px'}` }}></div>
                  <button
                    className={`rounded-[8px] cursor-pointer h-[48px] px-6 text-[16px] font-semibold relative z-10 transition-colors duration-300 ${toggle ? 'text-white' : 'text-gray-400'}`}
                    onClick={() => setToggle(true)}
                  >
                    Investors
                  </button>
                  <button
                    className={`rounded-[8px] cursor-pointer h-[48px] px-6 text-[16px] font-semibold relative z-10 transition-colors duration-300 ${!toggle ? 'text-white' : 'text-gray-400'}`}
                    onClick={() => setToggle(false)}
                  >
                    Developers
                  </button>
                </div>
                <div className="flex flex-col mt-6">
                  {!toggle && <div className="flex flex-col">
                    <div
                      className="bg-[#7e0087] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                        , cursor: 'pointer'
                      }}
                      onClick={() => toggleVisibility(0)}
                    >
                      <div className="Text1 text-[11px] lg:text-[14px]" style={{ cursor: "pointer" }}>
                        How does Hotlaunch.fun benefit developers.
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                        className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 0 ? 'rotate-180' : ''}`}>
                        <path d="m6 9 6 6 6-6"></path>
                      </svg>
                    </div>
                    {visibleSection === 0 && (
                      <div
                        className="bg-[#5d0663] rounded-[20px] lg:px-8 px-2.5 py-5"
                        style={{
                          boxShadow: '#8c00f5 0px 5px 10px 0px',
                          border: '2px solid #8c00f5',
                          zIndex: '-1',
                          marginTop: '-27px',
                        }}
                      >
                        <div
                          className="Text1 text-[11px] lg:text-[14px]"
                          style={{
                            paddingTop: '16px'
                          }}
                        >
                          Hotlaunch.fun makes token deployment fast, easy and
                          cheap. You can have a token up and running within a
                          minute, immediately catching the attention of thousands
                          of platform users. Not only! With the FEATURED option we
                          provide also the best exposure and marketing.
                        </div>
                      </div>
                    )}
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#7e0087] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(1)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px]"
                            style={{ cursor: "pointer" }}
                          >
                            Which coding skills do i need to deploy a token?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 1 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 1 && (
                          <div
                            className="bg-[#5d0663] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px',
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              NONE! All you have to do is fill in a couple details
                              and pay the deployement transaction fee.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#7e0087] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(2)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px] max-w-[90%]"
                            style={{ cursor: "pointer" }}
                          >
                            How much does it cost to launch a project with
                            Hotlaunch.fun?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 2 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 2 && (
                          <div
                            className="bg-[#5d0663] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px',
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              Peanuts! We currently charge something like 2 or 3$
                              for the BASIC launch plus gas fees and about 250$
                              for the FEATURED OPTION that will ensure your
                              project gets the best visibility and marketing
                              coverage .
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#7e0087] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(3)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px]"
                            style={{
                            }}
                          >
                            How can i start investing in Hotlaunch.fun?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 3 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 3 && (
                          <div
                            className="bg-[#5d0663] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px',
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              All you have to do is connect your wallet and you
                              are ready to go. You'll find a lot of projects on
                              our homepage, which you can easily filter to find
                              the right project for you, on the chain you prefer
                              to trade on.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#7e0087] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(4)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px]"
                            style={{ cursor: "pointer" }}
                          >
                            Are there trading fees on Hotlaunch.fun?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 4 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 4 && (
                          <div
                            className="bg-[#5d0663] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px',
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              YES! Just 1% on each trade You can get 50% of these
                              fees through your referral link that you find on
                              each page of the various tokens. Start earning now.
                              Refer your friends to Hotlaunch.fun!
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#7e0087] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(5)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px] max-w-[90%]"
                            style={{ cursor: "pointer" }}
                          >
                            Are there other ways to earn money through
                            Hotlaunch.fun?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 5 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 5 && (
                          <div
                            className="bg-[#5d0663] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px'
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              YES! You dont have to invest money to make money.
                              Start earning with your project. Refer your friends
                              and earn{' '}
                              <span style={{ fontWeight: 'bold' }}>
                                {' '}
                                50% of the fees{' '}
                              </span>
                              . Just grab your refferal link for free on each
                              token page and get started!
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>}

                  {toggle && <div className="flex flex-col">
                    <div
                      className="bg-[#7e0087] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                        , cursor: 'pointer'
                      }}
                      onClick={() => toggleVisibility(6)}
                    >
                      <div
                        className="Text1 text-[11px] lg:text-[14px]"
                        style={{ cursor: "pointer" }}
                      >
                        What is Hotlaunch.fun?
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                        className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 6 ? 'rotate-180' : ''}`}>
                        <path d="m6 9 6 6 6-6"></path>
                      </svg>
                    </div>
                    {visibleSection === 6 && (
                      <div
                        className="bg-[#5d0663] rounded-[20px] lg:px-8 px-2.5 py-5"
                        style={{
                          boxShadow: '#8c00f5 0px 5px 10px 0px',
                          border: '2px solid #8c00f5',
                          zIndex: '-1',
                          marginTop: '-27px'
                        }}
                      >
                        <div
                          className="Text1 text-[11px] lg:text-[14px]"
                          style={{
                            paddingTop: '16px'
                          }}
                        >
                          Hotlaunch.fun is a cutting-edge platform designed to
                          simplify the process of token deployment and provide
                          investors with early access to promising crypto projects
                          that are technically safe.
                        </div>
                      </div>
                    )}
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#7e0087] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(7)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px]"
                            style={{ cursor: "pointer" }}
                          >
                            How does Hotlaunch.fun benefit investors?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 7 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 7 && (
                          <div
                            className="bg-[#5d0663] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px'
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              Hotlaunch.fun offers exclusive early access to new
                              tokens before they are listed on major
                              DEXs,increasing the chances for significant gains.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#7e0087] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(8)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px]"
                            style={{ cursor: "pointer" }}
                          >
                            Is investing in Hotlaunch.fun benefit safe?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 8 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 8 && (
                          <div
                            className="bg-[#5d0663] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px'
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              Hotlaunch.fun manages both the liquidity pool and
                              the token contract, ensuring a much safer investment
                              environment compared to the "regular" market that is
                              infested with scammers who are after your money.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#7e0087] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(9)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px]"
                            style={{ cursor: "pointer" }}
                          >
                            How can i start investing in Hotlaunch.fun?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 9 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 9 && (
                          <div
                            className="bg-[#5d0663] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px'
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              All you have to do is connect your wallet and you
                              are ready to go. You'll find a lot of projects on
                              our homepage, which you can easily filter to find
                              the right project for you, on the chain you prefer
                              to trade on.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#7e0087] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(11)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px] max-w-[90%]"
                            style={{ cursor: "pointer" }}
                          >
                            What happens when a token reaches 69,000 USD market
                            cap?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 11 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 11 && (
                          <div
                            className="bg-[#5d0663] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px'
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              It's automatically listed on a DEX with 12k$ in
                              liquidity. At the same time, the LP tokens are burnt
                              and the contract renounced for enhanced safety.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#7e0087] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(12)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px] max-w-[90%]"
                            style={{ cursor: "pointer" }}
                          >
                            Are there other ways to earn money through
                            Hotlaunch.fun?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 12 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 12 && (
                          <div
                            className="bg-[#5d0663] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px'
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              YES! You dont have to invest money to make money.
                              Start earning with your project. Refer your friends
                              and earn{' '}
                              <span style={{ fontWeight: 'bold' }}>
                                {' '}
                                50% of the fees{' '}
                              </span>
                              . Just grab your refferal link for free on each
                              token page and get started!
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
            <div className='w-[454.78px] h-[420px] hidden md:flex'>
              <img src={faq} width={454} height={420} className='' />
            </div>
            <div className='w-[214px] h-[199px] flex md:hidden'>
              <img src={faq} width={214} height={200} className='' />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Faq
