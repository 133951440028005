
export default {
    multicallAddress: {
        1: '0x8329719d4acDCEA375083C31CdC6fb55Aacfa298',
        56: '0xaD1c9bCe385293Ec09b3Fb054fadDe70fd88ebc5',
        11155111: '0x39520b5161CC8A95ca32966b28100Ea033890404',
        137: '0x1156256625faa48840Aea2Cd6f96418FDB4370fB',
        8453: '0xA3Fc96C283F647F4d3Bd8D346118d4F2905b7355',
    },
    factoryAddress: {
        1: '0xfb5E6BDa6A1b0be568DdEd1Bd98e363C563dfd34',
        56: '0xA3Fc96C283F647F4d3Bd8D346118d4F2905b7355',
        11155111: '0x70852b0990671A3fD83c34760394b1789708e01a',
        137: '0xE1C7759AebCd688e5f23B7C7A96a2dB87582A9Ae',
        8453: '0xDe2358Aa13Bf3f37E02Bf5e48A8CB60fF90a0F05',
    },
    contractAddress: {
        1: '0x0000000000000000000000000000000000000000',
        56: '0x0000000000000000000000000000000000000000',
        11155111: '0x0000000000000000000000000000000000000000',
        137: '0x0000000000000000000000000000000000000000',
        8453: '0x0000000000000000000000000000000000000000',
    },
    WETHAddress: {
        1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        11155111: '0xb16F35c0Ae2912430DAc15764477E179D9B9EbEa',
        137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        8453: '0x4200000000000000000000000000000000000006',
    },
    routerAddress: {
        1: {
            'Uniswap': '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', 
            'Pancakeswap': '0xEfF92A263d31888d860bD50809A8D171709b7b1c',
            'Sushiswap': '0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F'
        },
        56: {
            'Uniswap': '0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24',
            'Pancakeswap': '0x10ED43C718714eb63d5aA57B78B54704E256024E',
            'Sushiswap': '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506'
        },
        11155111: {'Uniswap': '0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008'},
        137: {'Uniswap': '0xedf6066a2b290C185783862C7F4776A2C8077AD1'},
        8453: {
            'Uniswap': '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24',
            'Pancakeswap': '0x8cFe327CEc66d1C090Dd72bd0FF11d690C33a2Eb',
            'Sushiswap': '0x6BDED42c6DA8FBf0d2bA55B2fa120C5e0c8D7891',
            'Baseswap': '0x327Df1E6de05895d2ab08513aaDD9313Fe505d86'
        },
    },
    defaultAddress: {
        1: '0x0000000000000000000000000000000000000000',
        56: '0x0000000000000000000000000000000000000000',
        11155111: '0x0000000000000000000000000000000000000000',
        137: '0x0000000000000000000000000000000000000000',
        8453: '0x0000000000000000000000000000000000000000',
    },
}